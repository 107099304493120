<style>
.content {
  @apply flex w-full min-h-screen overflow-x-auto;
  background-color: #f5f5f5;
}
</style>

<script>
import Menu from "./Menu.svelte"
import QuickVideo from "./QuickVideo/index.svelte"
</script>

<QuickVideo />

<div class="content">
  <div class="min-h-screen"><Menu /></div>

  <div class="flex-1 py-6 px-4">
    <slot />
  </div>
</div>
