<style>
.background {
  @apply flex items-center justify-center;
  @apply fixed top-0 left-0 z-50;
  @apply w-full min-h-screen;
  @apply bg-black bg-opacity-50;
}
.modal {
  @apply bg-white w-96 rounded-sm shadow-sm px-4 py-6;
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import validate from "validate.js"
import API, { handleErrors } from "@local/utils/api"
import TextInput from "@local/components/form/TextInput.svelte"

const dispatch = createEventDispatcher()

export let data
let errors
let form = {
  limit: data?.limit || "",
}
const constraints = {
  limit: { presence: { allowEmpty: false } },
}

async function submit() {
  errors = validate(form, constraints)
  if (errors || !isValidThreshold(form.limit)) {
    return
  }

  try {
    await API.patch(`admin/coupons/${data._id}`, {
      json: {
        limit: parseInt(form.limit, 10),
      },
    }).json()
    dispatch("dataUpdated")
    dispatch("close")
  } catch (err) {
    errors = await handleErrors({ err })
  }
}

const isValidThreshold = (value) =>
  data && data.limit && parseInt(value, 10) > parseInt(data.limit, 10)
</script>

<div class="background">
  <div class="modal">
    <h2 class="mb-8 h2">Increase coupon limit</h2>
    <form on:submit|preventDefault="{submit}">
      <TextInput
        bind:value="{form.limit}"
        label="Limit"
        name="limit"
        errors="{errors && errors.limit}"
        class="mb-4"
        type="number"
      />
      <span
        class="block text-xs"
        class:invisible="{isValidThreshold(form.limit)}"
        >Value must be greater than {data.limit}</span
      >
      <div class="flex justify-end gap-2 mt-4">
        <button
          type="button"
          class="btn btn-outline-red"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button
          disabled="{!isValidThreshold(form.limit)}"
          type="submit"
          class="btn btn-solid-red"
          class:opacity-50="{!isValidThreshold(form.limit)}">Save</button
        >
      </div>
    </form>
  </div>
</div>
