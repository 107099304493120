<style>
.menu {
  @apply bg-gray-200 shadow-md border-r border-gray-300 h-full overflow-x-auto top-0 left-0 transition-all duration-300 ease-in-out;
}
.item {
  @apply flex text-black cursor-pointer items-center px-4 py-2 whitespace-nowrap;
}
.item:hover {
  color: #e63c24;
}
</style>

<script>
import { link } from "svelte-routing"
import { currentUser, deauth } from "@local/store/auth"
import Logo from "@local/components/images/Logo.svelte"
import Icon from "@local/components/images/Icon.svelte"

const itemsSidebar = [
  { to: "/", label: "Dashboard", icon: "queue_play_next" },
  { to: "/users", label: "Users", icon: "person", accessLevel: 3 },
  {
    to: "/workflows?type=medical",
    label: "Medical Workflows",
    icon: "medical_information",
    accessLevel: 3,
  },
  {
    to: "/workflows?type=educator",
    label: "Educator Workflows",
    icon: "history_edu",
    accessLevel: 3,
  },
  {
    to: "/calendar",
    label: "Calendar",
    icon: "calendar_month",
    accessLevel: 3,
  },
  { to: "/who-to-call", label: "Who to call", icon: "call", accessLevel: 3 },
  {
    to: "/cancellation-list",
    label: "Cancellations",
    icon: "event_busy",
    accessLevel: 3,
  },
  { to: "/payments", label: "Payments", icon: "local_atm", accessLevel: 1 },
  { to: "/coupons", label: "Coupons", icon: "percent", accessLevel: 1 },
  {
    to: "#",
    label: "Reports",
    icon: "query_stats",
    accessLevel: 1,
    subitem: [
      {
        to: "/reports/post-consults",
        label: "Post Consult",
        icon: "plagiarism",
        accessLevel: 1,
      },
      {
        to: "/reports/starseed",
        label: "Starseed",
        icon: "plagiarism",
        accessLevel: 1,
      },
      {
        to: "/reports/starseed-temp",
        label: "Starseed Temp",
        icon: "plagiarism",
        accessLevel: 1,
      },
      {
        to: "/reports/purchases",
        label: "Purchases",
        icon: "plagiarism",
        accessLevel: 1,
      },
    ],
  },
]

let isSubMenuOpen = {}
const toggleSubMenu = (index) => {
  isSubMenuOpen[index] = !isSubMenuOpen[index]
}
</script>

<nav class="menu w-24 md:w-52 transition-all duration-300 ease-in-out">
  <div class="py-8 px-4">
    <a href="/" use:link>
      <div class="md:hidden"><Logo size="small" /></div>
      <div class="hidden md:block"><Logo size="regular" /></div>
    </a>
  </div>

  <div class="mt-4">
    {#each itemsSidebar as item, index}
      {#if item.accessLevel && $currentUser && $currentUser.roleLevel <= item.accessLevel}
        <div class="py-2">
          {#if item.subitem?.length > 0}
            <button
              title="{item.label}"
              class="item justify-center md:justify-start w-full"
              on:click="{() => toggleSubMenu(index)}"
            >
              <Icon size="sm">{item.icon}</Icon>
              <span class="ml-2 md:inline hidden">{item.label}</span>
              <Icon size="sm" class="ml-2">
                {isSubMenuOpen[index] ? "expand_less" : "expand_more"}
              </Icon>
            </button>

            {#if isSubMenuOpen[index]}
              <div class="pl-6">
                {#each item.subitem as subitem}
                  <div class="py-2">
                    <a
                      title="{subitem.label}"
                      class="item"
                      href="{subitem.to}"
                      use:link
                    >
                      <Icon size="xl">{subitem.icon}</Icon>
                      <span class="ml-2 md:inline hidden">{subitem.label}</span>
                    </a>
                  </div>
                {/each}
              </div>
            {/if}
          {:else}
            <a
              title="{item.label}"
              class="item justify-center md:justify-start w-full"
              href="{item.to}"
              use:link
            >
              <Icon size="xl">{item.icon}</Icon>
              <span class="ml-2 md:inline hidden">{item.label}</span>
            </a>
          {/if}
        </div>
      {/if}
    {/each}
    <div title="Logout" class="py-2" on:click="{deauth}">
      <button class="item justify-center md:justify-start w-full">
        <Icon size="xl">logout</Icon>
        <span class="ml-2 md:inline hidden">Logout</span>
      </button>
    </div>
  </div>
</nav>

<!-- <div class="fixed z-50 h-full pb-4 w-36 overflow-y-auto bg-slate-900">
        <div class="absolute flex flex-col flex-shrink-0 w-full">
          <a href="/" class="flex justify-center my-10" use:link>
            <Logo class="w-12" />
          </a>
      
          {#each itemsSidebar as item}
            {#if item.accessLevel && $currentUser && $currentUser.roleLevel <= item.accessLevel}
              <a class="item" href="{item.to}" use:link>
                <Icon class="text-3xl">{item.icon}</Icon>
                <span class="text-sm">{item.label}</span>
              </a>
            {/if}
          {/each}
      
          <div class="item mt-8" on:click="{deauth}">
            <Icon class="text-3xl">logout</Icon>
            <span class="text-sm">{logoutText[$workflowLang]}</span>
          </div>
        </div>
      </div> -->
