<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import { link } from "svelte-routing"
import Pager from "@local/components/Pager.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import Icon from "@local/components/images/Icon.svelte"
import CreateCallOutcomeModal from "@local/components/CreateCallOutcomeModal.svelte"
import MultiSelect from "@local/components/MultiSelect.svelte"
import {
  setDataToLocalStorage,
  getDataFromLocalStorage,
} from "@local/utils/localStorage"

const queryClient = useQueryClient()
const localStorageKey = "whoToCall"
const reasonOptions = ["More than 30 days", "Less than 15 days"]
const subdomainsOptions = ["pennsylvania"]

let perFilter = 20
let showCallOutcomeModal = null
let storedFilters = getDataFromLocalStorage(localStorageKey)
let query = storedFilters?.query || ""
let queryVal = storedFilters?.query || ""
let reasons = storedFilters?.reasons || []
let reasonsVal = storedFilters?.reasons || []
let subdomains = storedFilters?.subdomains || []
let subdomainsVal = storedFilters?.subdomains || []
let page = storedFilters?.page || 0
let wfId

function search(pageNumber) {
  page = pageNumber
  query = queryVal
  reasons = reasonsVal
  subdomains = subdomainsVal
}

async function fetchWhoToCall(page, query, reasons, subdomains) {
  const searchParams = { page, perFilter, query, reasons, subdomains }
  const response = await API.get(`admin/who-to-call`, { searchParams })
  const rows = await response.json()
  const hasNextPage = rows.length > 0

  setDataToLocalStorage(localStorageKey, { page, query, reasons, subdomains })
  return { rows, hasNextPage, query }
}

async function openCallOutcomeModal(id) {
  showCallOutcomeModal = true
  wfId = id
}

function clearFilters() {
  queryVal = ""
  reasonsVal = []
  subdomainsVal = []
  queryClient.invalidateQueries("whoToCallFilters")
  search(0)
}

$: queryOptions = {
  queryKey: ["whoToCall", page, query, reasons, subdomains],
  queryFn: () => fetchWhoToCall(page, query, reasons, subdomains),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

{#if showCallOutcomeModal}
  <CreateCallOutcomeModal
    wfId="{wfId}"
    on:success="{() => queryClient.invalidateQueries('whoToCall')}"
    on:close="{() => (showCallOutcomeModal = null)}"
  />
{/if}

<Query options="{queryOptions}">
  <div class="p-5" slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="flex flex-row items-center justify-between mb-6">
        <h2 class="h2">
          {#if queryResult.data.query || reasons.length > 0}
            Who to call Search: "{queryResult.data.query} {reasonsVal}"
          {:else}Who to call{/if}
        </h2>
      </div>
      <div class="flex items-center gap-2 mb-2">
        <Pager
          bind:page="{page}"
          hasNextPage="{queryResult.data.hasNextPage}"
          isPreviousData="{queryResult.isPreviousData}"
        />
        <form
          class="flex items-center w-full"
          on:submit|preventDefault="{() => search(0)}"
        >
          <TextInput
            bind:value="{queryVal}"
            className="w-full md:w-1/4 md:ml-2 mt-2 md:mt-0"
            type="search"
            placeholder="Search by Full Name or Preferred Name or Email or Partner Origin"
          />
          {#key subdomainsVal.length === 0}
            <MultiSelect
              className="w-full md:w-1/4 md:ml-2 mt-2 md:mt-0"
              bind:value="{subdomainsVal}"
              placeholder="Partners"
            >
              {#each subdomainsOptions as subdomain}
                <option value="{subdomain}">{subdomain}</option>
              {/each}
            </MultiSelect>
          {/key}
          {#key reasonsVal.length === 0}
            <MultiSelect
              className="w-full md:w-1/4 md:ml-2 mt-2 md:mt-0"
              bind:value="{reasonsVal}"
              placeholder="Reasons"
            >
              {#each reasonOptions as reason}
                <option value="{reason}">{reason}</option>
              {/each}
            </MultiSelect>
          {/key}
          <button
            title="Clear filters"
            class="flex items-center justify-center w-10 h-10 ml-2 bg-white border border-gray-200 shadow-sm focus:outline-none"
            on:click="{clearFilters}"
            type="button"
          >
            <Icon size="3xl">clear</Icon>
          </button>
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 ml-2 bg-white border border-gray-200 shadow-sm focus:outline-none"
            type="submit"><Icon size="3xl">search</Icon></button
          >
        </form>
      </div>
      {#if queryResult.data.rows.length > 0}
        <table class="table">
          <thead>
            <th>Full / Preferred Name</th>
            <th>Phone</th>
            <th>Partner / Referral</th>
            <th>State / Province</th>
            <th>Reason</th>
            <th>Outcome</th>
          </thead>
          <tbody>
            {#each queryResult.data.rows as row}
              <tr>
                <td>{row.fullPreferredName}<br /><i>{row.email}</i></td>
                <td>{row.phone}</td>
                <td>{row.partnerReferral}</td>
                <td>{row.stateProvince}</td>
                <td>{row.reason}</td>
                <td>{row.callOutcome}</td>
                <td>
                  <span
                    class="cursor-pointer"
                    on:click="{() => openCallOutcomeModal(row.wfId)}"
                  >
                    <Icon>phone_missed</Icon>
                  </span>
                </td>
                <td>
                  <a use:link href="workflows/{row.wfId}">
                    <Icon>arrow_forward</Icon>
                  </a>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {:else}
        <div class="flex items-center justify-center mt-16">
          <h2 class="h2">No workflows at the moment</h2>
        </div>
      {/if}
    {/if}
  </div>
</Query>
